<template>
    <Modal title="查看权限" :width="640" v-model="showModel" :loading="loading">
        <Form class="plain-form" ref="viewForm" label-colon :label-width="90">
            <FormItem label="角色名称" prop="roleName">
                <div>{{ viewForm.roleName }}</div>
            </FormItem>
            <FormItem label="当前权限" prop="mail">
                <Tree ref="tree" :disabled="true" :data="treeData" show-checkbox multiple></Tree>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>

<script>
import {queryPermissions, reqGetRoleById} from "../../../api/system-api";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        roleId: Number
    },
    data() {
        return {
            loading: false,
            viewForm: {
                roleId: 0,
                roleName: '',
                description: '',
                delId: '',
            },
            treeData: [],
            showModel: false,
        }
    },
    watch: {
        value(val) {
            this.showModel = val;
        },
        showModel(val) {
            //当重新显示增加数据的时候重置整个form表单
            this.showModel = val;
            if (val) {
                this.treeData = [];
                this.getAllMenuList(this.roleId);
                this.getRoleById(this.roleId);
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    methods: {
        closeModal(val) {
            this.$emit('input', val);
        },
        getRoleById(roleId) {
            reqGetRoleById({roleId: roleId}).then(res => {
                if (res.data.code === 1) {
                    this.viewForm = res.data.data;
                } else {
                    this.$Message.error('查询失败');
                }
            }).catch(() => {
                this.$Message.error('查询失败');
            })
        },
        getAllMenuList(roleId) {
            let that = this;
            that.loading = true;
            queryPermissions({roleId: roleId}).then((res) => {
                if (res.data.code === 1) {
                    res.data.data.forEach(function (obj, i) {
                        res.data.data[i].disabled = true;
                        if (obj.children) {
                            obj.children.forEach(function (obj1, j) {
                                res.data.data[i].children[j].disabled = true;
                                if (obj1.children) {
                                    obj1.children.forEach(function (obj2, x) {
                                        res.data.data[i].children[j].children[x].disabled = true;
                                    })
                                }
                            })
                        }
                    });
                    that.loading = false;
                    that.treeData = res.data.data;
                } else {
                    that.loading = false;
                    that.$Message.error('查询失败');
                }
            }).catch(() => {
                that.loading = false;
                that.$Message.error('查询失败');
            })
        },
    },
    mounted() {
    }
}
</script>