<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>权限管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.roleName" placeholder="请输入角色名称" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="getRoles('0')">搜索</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="roleList" :loading="listLoading">
                    <template slot-scope="{ row, index }" slot="action">
                        <a v-if="row.roleId!==1&&permissionButton.view" @click="goView(row)" ghost>详情</a>
                        <Divider type="vertical" v-if="permissionButton.view"/>
                        <a v-if="row.roleId!==1&&permissionButton.edit" @click="goEdit(row)" ghost>编辑</a>
                    </template>
                </Table>

                <editPermissions v-model="showEditModal" :roleId="roleId" v-on:getRoles="getRoles"></editPermissions>
                <viewPermissions v-model="showViewModal" :roleId="roleId" v-on:getRoles="getRoles"></viewPermissions>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ current }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :total="total" :page-size="pageSize" :current="current" @on-change="changePage"
                              @on-page-size-change="changPageSize" :page-size-opts="[15,20,50,100]" show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>

</template>

<script>
import {reqGetRoleListPage} from "../../../api/system-api";
import permission from '../../../common/permission';
import viewPermissions from '../permissions/view'
import editPermissions from '../permissions/edit'

export default {
    name: 'TableView',
    data() {
        return {
            treeData: [],
            modalEditLoading: true,
            showEditModal: false,
            showViewModal: false,
            roleId: null,
            filter: {
                roleName: ''
            },
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    key: 'index',
                    width: 80
                },
                {
                    title: '角色名称',
                    key: 'roleName',
                    width: 300
                },
                {
                    title: '描述',
                    key: 'description',
                    minWidth: 180
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120
                }
            ],
            listLoading: false,
            pages: 1,
            total: 0,
            pageNum: 1,
            pageSize: 20,
            roleList: [],
            current: 0,
            permissionButton: {
                edit: false,
                view: false,
            }
        }
    },
    components: {
        viewPermissions,
        editPermissions
    },
    methods: {
        //编辑
        goEdit(row) {
            this.roleId = row.roleId;
            this.showEditModal = true;
        },
        //查看
        goView(row) {
            this.roleId = row.roleId;
            this.showViewModal = true;
        },
        //点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getRoles();
        },
        //每页显示的数据条数
        changPageSize(index) {
            //实时获取当前需要显示的条数
            this.pageSize = index;
            this.getRoles();
        },
        getRoles(status) {
            let that = this;
            that.listLoading = true;
            that.pageNum = status === '0' ? 1 : that.pageNum;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                roleName: that.filter.roleName
            };
            reqGetRoleListPage(para).then((res) => {
                that.listLoading = false;
                if (res.data.code === 1) {
                    that.total = res.data.data.total;
                    that.pages = res.data.data.pages;
                    that.current = res.data.data.current;
                    that.roleList = res.data.data.records;
                }
            }).catch(() => {
                that.listLoading = false;
            })
        },
    },
    mounted() {
        permission.getPermissionButton(this.$route.query.permissionId, this.permissionButton);
        this.getRoles();
    }
}
</script>
